import React from 'react'
import { useNavigate } from 'react-router-dom';
import '../../styles/Credits.css';
import gifFest from '../../assets/images/spinning_fest.gif';
import instaLogo from '../../assets/images/instgramlogo.webp';

export default function Credits() {
  const igNma = 'https://www.instagram.com/nma1._/';
  const igRams = 'https://www.instagram.com/ramsford__/';
  const igPhil = 'https://www.instagram.com/phi_mhp/';
  const igArez = 'https://www.instagram.com/areztation/';
  const igSabri = 'https://www.instagram.com/sabrinessaibia/';
  const igGrizzy = 'https://www.instagram.com/grizzyy.y/';
  const igAkalex = 'https://www.instagram.com/akalexmusic/';
  const navigate = useNavigate();

  const removeToken = () => {
    localStorage.removeItem('userToken');
    navigate('/home');
    };

  const handleIgLink = (igUrl) => {
    const userAgent = navigator.userAgent || window.opera;
    const username = igUrl.split('/').filter(Boolean).pop();

    if (/android/i.test(userAgent)) {
      window.location.href = `intent://instagram.com/_u/${username}#Intent;package=com.instagram.android;scheme=https;end`;
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = `instagram://user?username=${username}`;
    } else {
      window.open(igUrl, '_blank');
    }
  };
  return (
    <div className='clt-tube-border'>
      <img src={gifFest} alt='enjoy the ride clt gif' style={spinningGifStyles}></img>
      <div style={buttonContainerStyles}>
        <button style={MenuButtonStyles} onClick={() => navigate('/game')}>RESTART</button>
        </div>
        <div style={secondbuttonContainerStyles}>
        <button style={RestartButtonStyles} onClick={() => removeToken()}>MENU</button>
        </div>
        <img style={instaLogostyle} src={instaLogo} alt='instagram logo'></img>
      <div style={contentContainerStyles}>
        <p style={contentStyles} onClick={() => handleIgLink(igPhil)}>PHILIPPE MAHAPATRA DEVELOPER</p>
        <p style={contentStyles}onClick={() => handleIgLink(igRams)}>RAMSFORD SHEAMANG DEVELOPER</p>
        <p style={contentStyles} onClick={() => handleIgLink(igNma)}>NICOLAS MARIE-ANGELIQUE DESIGNER</p>
      </div>
    </div>
  )
}

const instaLogostyle = {
  top: '50%',
  position: 'absolute',
  left: '40%',
  height: '50px',
  width: 'auto',
  cursor: 'pointer',
  margin: '10px',
  transform: 'transform(-50%)',
}

const spinningGifStyles = {
  top: '7%',
  left: '50%',
  transform: 'translateX(-50%)',
  position: 'absolute',
  height: '20vh',
  width: 'auto',
}

const contentContainerStyles = {
  dipslay: 'flex',
  textAlign: 'auto',
  position: 'absolute',
  top: '58%',
  left: '50%',
  transform: 'translateX(-50%)',
}

const buttonContainerStyles = {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translateX(-50%)',
    marginRight: 'auto'
}

const secondbuttonContainerStyles = {
    ...buttonContainerStyles,
    top: '38%',
}

const RestartButtonStyles = {
    fontFamily: 'PressStart',
    fontSize: '25px',
    color: "red",
    padding: '10px',
    cursor: 'pointer',
    margin: '20px',
    border: '1px solid red',
    borderRadius: '10px',
    backgroundColor: 'transparent'
}

const MenuButtonStyles = {
    textAlign: 'center',
    fontFamily: 'PressStart',
    fontSize: '25px',
    color: "lightGreen",
    padding: '10px',
    cursor: 'pointer',
    margin: '1px',
    border: '1px solid lightGreen',
    borderRadius: '10px',
    backgroundColor: 'transparent'
}

const contentStyles = {
    padding: '15px',
    fontFamily: 'PressStart',
    fontSize: '15px',
    color: "black",
    cursor: 'pointer',
}
