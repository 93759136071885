import React from 'react';
import '../../styles/Landing.css'; 
import '../../assets/fonts/Megapunch_Italic.ttf';
import '../../assets/fonts/pressStart.ttf';
import '../../assets/fonts/varsity_regular.ttf';
import { Link } from 'react-router-dom';
import cltcolor from '../../assets/images/logo_clt.gif';
import logo from '../../assets/images/logo_ecole_de_la_teuf.webp';

export default function Landing () {
  const rsvpUrl = 'https://shotgun.live/events/event-342584-copy-1728313782475';
  return (
    <div>
        <img src={logo} alt='enjoy the ride clt gif' style={spinningGifStyles}></img>
        <Link to={rsvpUrl} style={{textDecoration:'none'}} target='_blank'>
            <h1 style={pressStartStyles} className='blinking'>INSCRIPTION</h1>
        </Link>
        <p style={copyrightStyles}> © CLT 2024</p>
    </div>
  )
}

const pressStartStyles = {
    fontFamily: 'Varsity',
    fontSize: '30px',
    color: "black",
    display: 'flex',
    justifyContent: 'center',
    top: '65%',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    alignItems: 'center',
  }
  
  const copyrightStyles = {
    fontFamily: 'Varsity',
    fontSize: '1em',
    bottom: '10%',
    left: '50%',
    transform: 'translateX(-50%)',
    color: 'black',
    position: 'absolute',
  }
  
  const spinningGifStyles = {
    top: '20%',
    left: '50%',
    transform: 'translateX(-50%)',
    position: 'absolute',
    height: '30vh',
    width: 'auto',
  }