import React, { useEffect, useRef } from 'react'
import '../../styles/Leaderboard.css'

export default function Leaderboard() {
  const [leaderboardData, setLeaderboardData] = React.useState([]);
  const instagramNameRef = useRef('@');
  const [error, setError] = React.useState('');
  const playButtonRef = useRef(null);

  useEffect(() => {
    const button = playButtonRef.current;
    button.addEventListener('click', (event) => handlePlayClick(event));
    button.addEventListener('touchstart', (event) => handlePlayClick(event));
    const fetchLeaderboard = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/getallscore`, {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (!response.ok) {
          console.log('error database');
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setLeaderboardData(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchLeaderboard();
    return () => {
      button.removeEventListener('click', handlePlayClick);
      button.removeEventListener('touchstart', handlePlayClick);
    };
  }, []);


  async function getUserOrCreate(username) {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/${username}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        console.log('error on db');
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  function usertimeConverted(time) {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const strseconds = Math.floor(seconds);
    const strmiliseconds = Math.floor((seconds - strseconds) * 1000);
    return `${minutes}'${strseconds}"${strmiliseconds}`;
  }


  const handleInputChange = (e) => {
    instagramNameRef.current = e.target.value;
  };

  const handlePlayClick = async (event) => {
    if (event) event.preventDefault();
    const instagramName = instagramNameRef.current;
    if (instagramName.trim() !== '' && instagramName.length <= 30) {
      try {
        setError('');
        window.location.href = '/game';
      } catch (error) {
        console.log('an error occurred');
        console.error(error);
      }
    } else {
      setError('Instagram name cannot be empty');
    }
  };

  return (
    <div className='clt-tube-border'>
      <input
        className="arcade-textfield"
        style={{ color: 'white'}}
        type="text"
        placeholder="ENTRE TON PSEUDO IG" 
        onChange={handleInputChange}
      />
      {error && <div className="arcade-textfield" style={{ color: 'red', top: '13%' }}>{error}</div>}
      <button ref={playButtonRef} style={playStyles}>PLAY</button>
      <div style={leaderboardStyles}>
      <h1 style={TitleLeader}>LEADERBOARD</h1>
      <div>
        <div style={headerRowStyles}>
          <div>NAME</div>
          <div>SCORE</div>
        </div>
        {leaderboardData.map((user, index) => (
          <div key={index} style={rowStyles}>
            <div>{user.username}</div>
            <div style={timeStyle}>{usertimeConverted(user.score)}</div>
          </div>
        ))}
      </div>
    </div>
    </div>
  )
}

const playStyles = {
  backgroundColor: 'transparent',
  border: 'none',
  zIndex: '1',
  fontFamily: 'PressStart',
  fontSize: '22px',
  color: "black",
  top: '25%', // Adjust the 'top' position
  left: '50%',
  transform: 'translateX(-50%)',
  position: 'absolute',
  cursor: 'pointer',
  border : '2px solid black',
}

const TitleLeader = {
  top: '-1%', // Adjust the 'top' position
  textAlign: 'center',
  fontFamily: 'PressStart',
  fontSize: '22px',
  color: "black",
  left: '50%',
  transform: 'translateX(-50%)',
  position: 'absolute',
  cursor: 'pointer',
}


const leaderboardStyles = {
  top: '33%', // Adjust the 'top' position
  position: 'absolute',
  backgroundColor: 'transparent',
  left : '50%',
  transform: 'translateX(-50%)',
  fontFamily: 'PressStart',
  color: 'white',
  padding: 30,
  width: '100%',
  maxWidth: '290px', // typical iPhone portrait width
  margin: '0 auto', // center the container if viewport width > 375px
};

const headerRowStyles = {
  color: 'black',
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: 4,
  borderBottom: '1px solid black',
  fontSize: '16px',
};

const rowStyles = {
  ...headerRowStyles, // spread operator to copy styles from headerRowStyles
  paddingTop: 4,
  fontSize: '15px',
};

const timeStyle = {
  color: 'red',
}